import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Wewnątrz SolveProblem lub innego komponentu

const apiUrl = process.env.REACT_APP_API_SOURCE;

const SolveProblem = ({
  setShowSolveProblem,
  setAverageElo,
  averageElo,
  problemsData,
}) => {
  const navigate = useNavigate();
  const [startTime, setStartTime] = useState(null);
  const [currentProblem, setCurrentProblem] = useState(problemsData[0]);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [isHintUsed, setIsHintUsed] = useState(false);
  const [isSolutionViewed, setIsSolutionViewed] = useState(false);
  const [endTime, setEndTime] = useState(null);
  const [answers, setAnswers] = useState([]);
  const [isAnswerChecked, setIsAnswerChecked] = useState(false);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(false);
  const [problemsSession, setProblemsSession] = useState([]);
  const token = localStorage.getItem("token");

  useEffect(() => {
    const currentTime = Math.floor(Date.now() / 1000); // Linux epoch w sekundach
    setStartTime(currentTime);
  }, [currentProblem]);

  // Shuffle answers and store them in state
  useEffect(() => {
    if (currentProblem) {
      const shuffledAnswers = [
        { text: currentProblem.true_answer, isCorrect: true },
        { text: currentProblem.false_answer1, isCorrect: false },
        { text: currentProblem.false_answer2, isCorrect: false },
        { text: currentProblem.false_answer3, isCorrect: false },
      ].sort(() => Math.random() - 0.5);
      setAnswers(shuffledAnswers);
    }
  }, [currentProblem]);

  const handleCheckAnswer = async () => {
    if (!selectedAnswer || isAnswerChecked) return; // Zapobiegaj wielokrotnemu kliknięciu

    const isCorrect = selectedAnswer.isCorrect;
    setEndTime(Math.floor(Date.now() / 1000));
    setIsAnswerChecked(true);
    setIsSubmitDisabled(true); // Blokuj wielokrotne kliknięcie

    // Dodanie danych do lokalnej listy problemsSession
    setProblemsSession((prev) => [
      ...prev,
      {
        problem_id: currentProblem.problem_id,
        is_solved: isCorrect ? 1 : 0,
        is_solution_viewed: isSolutionViewed ? 1 : 0,
        problem_elo: currentProblem.problem_elo,
        question: currentProblem.question,
        true_answer: currentProblem.true_answer,
        selected_answer: selectedAnswer.text,
      },
    ]);

    // Przygotowanie danych do wysłania
    const problemAttemptData = {
      problem_id: currentProblem.problem_id,
      field_id: currentProblem.field_id,
      is_solved: isCorrect,
      is_hint_used: isHintUsed,
      is_solution_viewed: isSolutionViewed,
      start_time: startTime,
      end_time: Math.floor(Date.now() / 1000),
    };

    try {
      // Wysłanie danych do API
      const response = await fetch(`${apiUrl}solve_problem_attempt/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        body: JSON.stringify(problemAttemptData),
      });

      const data = await response.json();
      setAverageElo(data.average_user_elo);

      if (isCorrect) {
        console.log("Poprawna odpowiedź!");
      } else {
        console.log("Niepoprawna odpowiedź!");
      }
    } catch (error) {
      console.error("Błąd podczas wysyłania danych:", error);
    }
  };

  const handleHint = () => {
    setIsHintUsed(true);
  };

  const handleSolution = () => {
    setIsSolutionViewed(true);
  };

  const handleNextProblem = () => {
    if (problemsData.length > 1) {
      problemsData.shift();
      setCurrentProblem(problemsData[0]);
    } else {
      setTimeout(() => {
        navigate("/session-stats", { state: { problemsSession } });
      }, 1000);
      setShowSolveProblem(false); // Wyjdź, jeśli brak dalszych problemów
    }

    // Reset stanu
    setIsHintUsed(false);
    setIsSolutionViewed(false);
    setIsAnswerChecked(false);
    setSelectedAnswer(null);
    setEndTime(null);
    setIsSubmitDisabled(false);
  };

  const renderSessionStatus = () => {
    if (problemsSession.length === 0) return null; // Nie pokazuj nic dla pierwszego problemu

    return (
      <div style={{ display: "flex", gap: "10px", marginTop: "20px" }}>
        {problemsSession.map((session, index) => {
          let bgColor;
          if (session.is_solved === 0) {
            bgColor = "red"; // Czerwony kafelek - is_solved == 0
          } else if (
            session.is_solved === 1 &&
            session.is_solution_viewed === 1
          ) {
            bgColor = "orange"; // Pomarańczowy kafelek - is_solved == 1 oraz is_solution_viewed == 1
          } else if (session.is_solved === 1) {
            bgColor = "green"; // Zielony kafelek - is_solved == 1
          }
          return (
            <div
              key={index}
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: bgColor,
                border: "1px solid #000",
              }}
            ></div>
          );
        })}
      </div>
    );
  };

  return (
    <div className="space-y-4">
      <h3>Średnie ELO użytkownika: {averageElo}</h3>
      <h4>ELO zadania: {currentProblem.problem_elo}</h4>
      <div className="space-y-2">
        <p>{currentProblem.question}</p>
        <ul className="flex gap-2">
          {answers.map((answer, index) => (
            <li key={index}>
              <button
                className="border border-slate-300"
                onClick={() => setSelectedAnswer(answer)}
                style={{
                  backgroundColor:
                    selectedAnswer === answer ? "#f0f0f0" : "transparent",
                  color: isAnswerChecked
                    ? answer.isCorrect
                      ? "green" // Zielony dla poprawnej odpowiedzi
                      : answer === selectedAnswer
                      ? "red" // Czerwony dla wybranej błędnej odpowiedzi
                      : "black"
                    : "black", // Domyślny kolor przed sprawdzeniem
                }}
              >
                {answer.text}
              </button>
            </li>
          ))}
        </ul>
      </div>

      <div className="flex gap-4">
        <button onClick={handleHint}>Pokaż podpowiedź</button>
        {isHintUsed && <p>{currentProblem.hint}</p>}

        <button onClick={handleSolution}>Pokaż rozwiązanie</button>
        {isSolutionViewed && <p>{currentProblem.solution}</p>}

        <button onClick={handleCheckAnswer} disabled={isSubmitDisabled}>
          Sprawdź odpowiedź
        </button>

        <button
          onClick={handleNextProblem}
          disabled={!selectedAnswer || !endTime}
        >
          Następne zadanie
        </button>
      </div>

      <h4>Obecna sesja:</h4>
      {renderSessionStatus()}
    </div>
  );
};

export default SolveProblem;
