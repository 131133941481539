// App.js
import React, { useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/pages/Login";
import Register from "./components/pages/Register";
import Settings from "./components/pages/Settings";
import Dashboard from "./components/pages/Dashboard";
import SolveProblem from "./components/pages/SolveProblem";
import SessionStats from "./components/pages/SessionStats";
import UserStats from "./components/pages/UserStats";
import FieldsPreferences from "./components/pages/FieldsPreferences"; // Dodaj import
import PasswordReset from "./components/pages/PasswordReset";
import PasswordResetConfirm from "./components/pages/PasswordResetConfirm";

const App = () => {
  const [showUserStats, setShowUserStats] = useState(false);
  const [showSolveProblem, setShowSolveProblem] = useState(false);
  const [showFieldsPreferences, setShowFieldsPreferences] = useState(false); // Dodaj stan
  const [problemsData, setProblemsData] = useState([]); // Stan do przechowywania listy problemów
  const [averageElo, setAverageElo] = useState(0); // Stan do przechowywania średniego ELO
  const [problemsSession, setProblemsSession] = useState([]);

  console.log(showUserStats, showSolveProblem, showFieldsPreferences);

  return (
    <Router>
      <Routes>
        <Route path="/settings" element={<Settings />} />
        <Route
          path="/session-stats"
          element={<SessionStats problemsSession={problemsSession} />}
        />
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/password_reset" element={<PasswordReset />} />
        <Route
          path="/password_reset/confirm"
          element={<PasswordResetConfirm />}
        />
        <Route
          path="/"
          element={
            showUserStats ? (
              <UserStats setShowUserStats={setShowUserStats} />
            ) : showSolveProblem ? (
              <SolveProblem
                setShowSolveProblem={setShowSolveProblem}
                problemsData={problemsData} // Przekazanie problemów do SolveProblem
                averageElo={averageElo} // Przekazanie średniego ELO do SolveProblem
                setAverageElo={setAverageElo}
              />
            ) : showFieldsPreferences ? (
              <FieldsPreferences
                setShowFieldsPreferences={setShowFieldsPreferences}
              />
            ) : (
              <Dashboard
                setShowUserStats={setShowUserStats}
                setShowSolveProblem={setShowSolveProblem}
                setShowFieldsPreferences={setShowFieldsPreferences}
                setProblemsData={setProblemsData} // Przekazanie funkcji do aktualizacji problemów
                setAverageElo={setAverageElo} // Przekazanie funkcji do aktualizacji średniego ELO
              />
            )
          }
        />
      </Routes>
    </Router>
  );
};

export default App;
