import React, { useEffect, useState } from "react";
import axios from "axios";
import { Line } from "react-chartjs-2";
import "chart.js/auto";

const apiUrl = process.env.REACT_APP_API_SOURCE;

const UserStats = ({ setShowUserStats }) => {
  const [dates, setDates] = useState([]);
  const [problemsAttemptedData, setProblemsAttemptedData] = useState({});
  const [problemsSolvedData, setProblemsSolvedData] = useState({});
  const [avgEloGainData, setAvgEloGainData] = useState({});

  const handleBackToDashboard = () => {
    setShowUserStats(false); // Przełącz stan, aby wrócić do Dashboard
  };

  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}user_stats_view/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });

        const data = response.data;
        setDates(Object.keys(data).flatMap((date) => Object.keys(data[date])));

        const problemsAttempted = {};
        const problemsSolved = {};
        const avgEloGain = {};

        Object.keys(data).forEach((date) => {
          Object.keys(data[date]).forEach((fieldId) => {
            if (!problemsAttempted[fieldId]) {
              problemsAttempted[fieldId] = [];
              problemsSolved[fieldId] = [];
              avgEloGain[fieldId] = [];
            }
            problemsAttempted[fieldId].push(
              data[date][fieldId].problems_attempted
            );
            problemsSolved[fieldId].push(data[date][fieldId].problems_solved);
            avgEloGain[fieldId].push(data[date][fieldId].avg_elo_gain);
          });
        });

        setProblemsAttemptedData(problemsAttempted);
        setProblemsSolvedData(problemsSolved);
        setAvgEloGainData(avgEloGain);
      } catch (error) {
        console.error("Error fetching stats:", error);
      }
    };

    fetchData();
  }, [token]);

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Date",
        },
      },
      y: {
        title: {
          display: true,
          text: "Count",
        },
      },
    },
  };

  const generateLineData = (data, label) => {
    const datasets = Object.keys(data).map((fieldId) => ({
      label: `Field ${fieldId}`,
      data: data[fieldId],
      borderColor: `hsl(${Math.random() * 360}, 70%, 50%)`, // Random color for each field_id
      backgroundColor: `hsla(${Math.random() * 360}, 70%, 70%, 0.2)`, // Random color for each field_id
      fill: true,
    }));

    return {
      labels: dates,
      datasets,
    };
  };

  return (
    <div className="grid gap-4 container">
      <h1>Twoje Statystyki</h1>

      <button onClick={handleBackToDashboard}>Powrót do Dashboardu</button>

      <div className="grid lg:grid-cols-2">
        <div className="max-w-sm chart-container">
          <h2>Próby rozwiązania zadań</h2>
          <Line
            data={generateLineData(
              problemsAttemptedData,
              "Próby rozwiązania zadań"
            )}
            options={chartOptions}
          />
        </div>

        <div className="max-w-sm chart-container">
          <h2>Rozwiązane zadania</h2>
          <Line
            data={generateLineData(problemsSolvedData, "Rozwiązane zadania")}
            options={chartOptions}
          />
        </div>

        <div className="max-w-sm chart-container">
          <h2>Średni wzrost ELO</h2>
          <Line
            data={generateLineData(avgEloGainData, "Średni wzrost ELO")}
            options={chartOptions}
          />
        </div>
      </div>
    </div>
  );
};

export default UserStats;
