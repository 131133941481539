import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const apiUrl = process.env.REACT_APP_API_SOURCE;

const Dashboard = ({
  setShowUserStats,
  setShowSolveProblem,
  setShowFieldsPreferences,
  setProblemsData,
  setAverageElo,
}) => {
  const [username, setUsername] = useState("");
  const [selectedFields, setSelectedFields] = useState([]);
  const [availableFields, setAvailableFields] = useState([]);
  const [problemsCount, setProblemsCount] = useState(0);
  const [averageUserElo, setAverageUserElo] = useState(0);
  const [levelModifier, setLevelModifier] = useState(0);
  const [mobileProblems, setMobileProblems] = useState(false);
  const [endlessMode, setEndlessMode] = useState(false);
  const [testMode, setTestMode] = useState(false);
  const [newFieldId, setNewFieldId] = useState("");

  const token = localStorage.getItem("token");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${apiUrl}user_dashboard_data/`, {
          headers: {
            Authorization: `Token ${token}`,
          },
        });

        const data = response.data;
        setUsername(data.username);
        setSelectedFields(data.selected_fields);
        setAvailableFields(data.available_fields);
        setProblemsCount(data.problems_count);
        setAverageUserElo(data.average_user_elo);
        setLevelModifier(data.preferences.level_modifier);
        setMobileProblems(data.preferences.mobile_problems);
        setEndlessMode(data.preferences.endless_mode);
        setTestMode(data.preferences.test_mode);
      } catch (error) {
        console.error("Error fetching dashboard data:", error);
      }
    };

    fetchData();
  }, [token]);

  const handleAddField = () => {
    const newField = availableFields.find(
      (field) => field.field_id === parseInt(newFieldId)
    );
    if (
      newField &&
      !selectedFields.some((field) => field.field_id === newField.field_id)
    ) {
      setSelectedFields([...selectedFields, newField]);
    }
  };

  const handleRemoveField = (fieldId) => {
    setSelectedFields(
      selectedFields.filter((field) => field.field_id !== fieldId)
    );
  };

  const handleSolveProblems = async () => {
    const selectedFieldIds = selectedFields.map((field) => field.field_id);
    const preferences = {
      level_modifier: levelModifier,
      mobile_problems: mobileProblems,
      endless_mode: endlessMode,
      test_mode: testMode,
    };

    try {
      const response = await axios.post(
        `${apiUrl}start_solve_problems/`,
        {
          preferences,
          selected_fields: selectedFieldIds,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      setAverageElo(response.data.average_user_elo);
      setProblemsData(response.data.final_problems);
      setShowSolveProblem(true); // Przełącz widok na SolveProblem
    } catch (error) {
      console.error("Error starting solve problems:", error);
    }
  };

  if (!username) return <div>Loading...</div>;

  return (
    <div className="space-y-4">
      <h1>Rozwiązuj zadania!</h1>
      <div>
        <h2>Wybrane kategorie:</h2>
        <table>
          {selectedFields.map((field) => (
            <tr key={field.field_id}>
              <td>{field.field_name}</td>
              <td>{field.user_field_elo}</td>
              <td>
                <button onClick={() => handleRemoveField(field.field_id)}>
                  Usuń
                </button>
              </td>
            </tr>
          ))}
        </table>
      </div>

      <div className="flex gap-4">
        <select
          className="rounded border border-slate-300"
          onChange={(e) => setNewFieldId(e.target.value)}
          value={newFieldId}
        >
          <option value="">Wybierz kategorię</option>
          {availableFields.map((field) => (
            <option key={field.field_id} value={field.field_id}>
              {field.field_name}
            </option>
          ))}
        </select>
        <button onClick={handleAddField} className="btn">
          Dodaj kategorię
        </button>
        <button onClick={() => setShowFieldsPreferences(true)}>
          Zarządzaj Polami
        </button>
      </div>

      <form
        className="flex flex-col gap-2"
        onSubmit={(e) => {
          e.preventDefault();
          handleSolveProblems();
        }}
      >
        <div className="flex gap-2">
          <label htmlFor="difficulty">Poziom trudności:</label>
          <select
            className="rounded border border-slate-300"
            value={levelModifier}
            onChange={(e) => setLevelModifier(parseInt(e.target.value))}
          >
            <option value={-300}>Łatwy</option>
            <option value={0}>Średni</option>
            <option value={300}>Trudny</option>
          </select>
        </div>

        <div className="flex gap-2">
          <input
            type="checkbox"
            checked={mobileProblems}
            onChange={(e) => setMobileProblems(e.target.checked)}
          />
          <label htmlFor="mobile_problems">Zadania bez obliczeń</label>
        </div>
        <div className="flex gap-2">
          <input
            type="checkbox"
            checked={endlessMode}
            onChange={(e) => setEndlessMode(e.target.checked)}
          />
          <label htmlFor="endless_mode">Tryb bez końca</label>
        </div>

        <div className="flex gap-2">
          <input
            type="checkbox"
            checked={testMode}
            onChange={(e) => setTestMode(e.target.checked)}
          />
          <label htmlFor="test_mode">Tryb testowy</label>
        </div>

        <div>
          <button type="submit" className="btn">
            Rozwiązuj!
          </button>
        </div>
      </form>

      <div className="space-y-2">
        <h2>Świetnie {username}!</h2>
        <p>
          Rozwiązałeś z nami już {problemsCount} zadań. Twój obecny ranking to{" "}
          {averageUserElo}.
        </p>
        <button onClick={() => setShowUserStats(true)} className="btn">
          Zobacz swoje statystyki
        </button>
      </div>
    </div>
  );
};

export default Dashboard;
