import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const SessionStats = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // const history = useHistory();

  // Odczytanie przekazanej listy problemsSession z state
  const problemsSession = location.state?.problemsSession || [];

  const handleReturnToDashboard = () => {
    navigate("/");
  };
  console.log("Otrzymane dane:", problemsSession); // Dodaj logowanie

  return (
    <div className="space-y-4">
      <h2>Statystyki Sesji</h2>

      <button
        onClick={handleReturnToDashboard}
        style={{
          padding: "10px 20px",
          fontSize: "16px",
          backgroundColor: "#4CAF50",
          color: "white",
          border: "none",
          cursor: "pointer",
          borderRadius: "5px",
        }}
      >
        Powrót do Dashboardu
      </button>

      <table style={{ width: "100%", borderCollapse: "collapse" }}>
        <thead>
          <tr>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              Nr Problemu
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              Pytanie
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              Odpowiedź Użytkownika
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              Poprawna Odpowiedź
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              Czy Poprawna?
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              Podpowiedź Użyta
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              Rozwiązanie Wyświetlone
            </th>
          </tr>
        </thead>
        <tbody>
          {problemsSession.map((session, index) => (
            <tr key={index}>
              <td
                style={{
                  border: "1px solid black",
                  padding: "8px",
                  textAlign: "center",
                }}
              >
                {index + 1}
              </td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                {session.question}
              </td>
              <td
                style={{
                  border: "1px solid black",
                  padding: "8px",
                  textAlign: "center",
                }}
              >
                {session.selected_answer}
              </td>
              <td
                style={{
                  border: "1px solid black",
                  padding: "8px",
                  textAlign: "center",
                }}
              >
                {session.true_answer}
              </td>
              <td
                style={{
                  border: "1px solid black",
                  padding: "8px",
                  textAlign: "center",
                }}
              >
                {session.is_solved ? "Tak" : "Nie"}
              </td>
              <td
                style={{
                  border: "1px solid black",
                  padding: "8px",
                  textAlign: "center",
                }}
              >
                {session.is_hint_used ? "Tak" : "Nie"}
              </td>
              <td
                style={{
                  border: "1px solid black",
                  padding: "8px",
                  textAlign: "center",
                }}
              >
                {session.is_solution_viewed ? "Tak" : "Nie"}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SessionStats;
